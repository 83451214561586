/* eslint-disable max-lines-per-function */
import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import styles from './index.module.scss';

const HomePage = dynamic(import('./vip/index.page'), { ssr: false });

const Home = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>PotPlayer</title>
      </Head>
      <HomePage />
    </div>
  );
};

export default Home;
